<template>
  <div class="church-presentation-template">
    <page-header>
      <h1 class="d-none">Add/Edit Placement Date</h1>
    </page-header>
    <page-body>
      <section class="section-1 bg-block mb-4">
        <h4 v-if="locationDetail.org_name !== ``">
          {{ locationDetail.org_name }}
          <div class="change-link" @click="showLocationDropdown">&nbsp; {{ translations.tcChange }}</div>
        </h4>
        <h1 class="sm mb-4">{{ translations.tcAddOrEditPlacementDate }}</h1>
        <div class="body">
          <b-form>
            <b-form-group class="form-element">
              <div class="g-row">
                <div class="g-col-1 d-flex">
                  <b-form-select v-if="locationDetail.org_name === ``" v-model="selectedLocationKey"
                    :options="locationsForSelection" @change="handleLocationChange($event)"
                    class="form-control flex-1 g-select">
                    <template slot="first">
                      <option :value="null" disabled>{{ translations.tcSelectLocation }}</option>
                    </template>
                  </b-form-select>
                </div>
                <div class="g-col-2"></div>
              </div>
            </b-form-group>
          </b-form>
        </div>
        <div class="date-select-1 mb-3">
          <strong>{{ translations.tcDate }}</strong>
          <b-form-datepicker id="placementDate" v-model="plc.mtg_start_date" class="mb-2 contact_date"
            :locale="prefCulture" :placeholder="translations.tcDatePickerPlaceholder"
            :label-help="translations.tcCalHelp" :label-no-date-selected="translations.tcNoDateSelected"
            :show-decade-nav="showDecadeNav" :hide-header="hideHeader">
            <template #button-content style="padding: 12px">
              <img src="@/assets/svgs/iCalendar.svg" class="cal-icon" alt="calendar icon" />
            </template>
          </b-form-datepicker>
        </div>
        <b-row>
          <b-col sm="3">
            <div class="time-select-1 mb-3">
              <strong>{{ translations.tcStartTime }}</strong>
              <br />
              <b-form-timepicker v-model="plc.mtg_start_time" minutes-step="5" :locale="prefCulture"
                :label-close-button="translations.tcClose" :label-no-time-selected="translations.tcNoTimeSelected">
              </b-form-timepicker>
            </div>
          </b-col>
          <b-col sm="3">
            <div class="time-select-1 mb-3">
              <strong>{{ translations.tcEndTime }}</strong>
              <br />
              <b-form-timepicker v-model="plc.mtg_end_time" minutes-step="5" :locale="prefCulture"
                :label-close-button="translations.tcClose" :label-no-time-selected="translations.tcNoTimeSelected">
              </b-form-timepicker>
            </div>
          </b-col>
        </b-row>
      </section>
      <section v-if="userSelectedLocationKey.length > 0" class="section-2 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">{{ translations.tcCoordinator }}</h2>
          <ToggleArrow @click="section2 = !section2" section="toggleMe" :status="section2"></ToggleArrow>
        </header>
        <div class="body" v-if="section2">
          <div class="g-row mb-0 mb-sm-3">
            <div class="g-col-1 d-flex mb-3 mb-sm-0">
              <b-form-select v-model="plc.coordinator_ind_key"
                :options="[...convertedPlacementFormOptions.members, ...searchedCoordinators]"
                class="form-control col-left-input g-select flex-0 mr-3">
                <template slot="first">
                  <option :value="null" disabled>{{ translations.tcSelectCoordinator }}</option>
                </template>
              </b-form-select>
              <b-button :name="`coordinatorFindButton`" variant="primary" class="flex-1 btn-pl-xsm"
                @click="showModal($event, 'personSearchModal')">{{ translations.tcFind }}</b-button>
            </div>
          </div>
        </div>
      </section>
      <section v-if="userSelectedLocationKey.length > 0" class="section-3 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">
            {{ translations.tcParticipants }}
          </h2>
          <ToggleArrow @click="section3 = !section3" section="toggleMe" :status="section3"></ToggleArrow>
        </header>
        <div class="body" v-if="section3">
          <div class="g-row mb-0 mb-sm-3">
            <div class="g-col-1 d-flex mb-3 mb-sm-0">
              <b-form-select v-model="selectedParticipant" :options="[...convertedPlacementFormOptions.members]"
                class="form-control col-left-input g-select flex-0 mr-3" @change="selectAParticipant($event)">
                <template slot="first">
                  <option :value="null" disabled>{{ translations.tcAddParticipant }}</option>
                </template>
              </b-form-select>
              <b-button :name="`participantFindButton`" variant="primary" class="flex-1 btn-pl-xsm"
                @click="showModal($event, 'personSearchModal')">{{ translations.tcFind }}</b-button>
            </div>
          </div>
        </div>
        <div class="body" v-if="section3">
          <div class="presentation-specs mb-2 list-border" v-for="(pct, index) in plc.plc_participants" :key="index">
            <div class="font-weight-bold">
              <div class="participant">{{ pct.name }}</div>
              <b-button :name="`participantRemoveButton`" variant="tertiary" class="flex-1 btn-pl-xsm ml-5"
                @click="removeParticipant(pct.pxi_ind_key)">{{ translations.tcRemove }}</b-button>
            </div>
          </div>
          <div class="presentation-specs" v-if="plc.plc_participants.length === 0">
            <div class="font-weight-bold">
              <span class="value">{{ translations.tcNoParticipantsSelected }}</span>
            </div>
          </div>
        </div>
      </section>
      <section v-if="userSelectedLocationKey.length > 0" class="section-4 bg-block mb-4">
        <header class="toggle-header mb-4">
          <h2 class="font-style-1">
            {{ translations.tcScriptures }}
          </h2>
          <ToggleArrow @click="section4 = !section4" section="section4" :status="section4"></ToggleArrow>
        </header>
        <div class="body" v-if="section4">
          <div class="g-row mb-0 mb-sm-3">
            <div class="g-col-1 d-flex mb-3 mb-sm-0" style="padding-left: 24px;">
              <b-form-checkbox id="cbox1" v-model="plc.no_scriptures_required_flag" name="cbox1" :value="true"
                class="mb-3" :unchecked-value="false"></b-form-checkbox>
              <label for="cbox1" style="margin: 4px 0 0 -52px;">
                <h3 class="flex-1 mr-3 text-left font-style-2" style="padding-left: 30px;">
                  {{ translations.tcNoScripturesRequired }}
                </h3>
              </label>
            </div>
          </div>
          <div class="g-row mb-0 mb-sm-3" v-if="!plc.no_scriptures_required_flag">
            <div class="g-col-1 d-flex mb-3 mb-sm-0">
              <b-form-select v-model="selectedProduct" :options="[...convertedPlacementFormOptions.products]"
                class="form-control col-left-input g-select flex-0 mr-3" @change="selectAProduct($event)">
                <template slot="first">
                  <option :value="null" disabled>{{ translations.tcAddProduct }}</option>
                </template>
              </b-form-select>
            </div>
          </div>
        </div>
        <div class="body" v-if="section4">
          <div class="product-container list-border" v-for="(prd, index) in plc.plc_products" :key="index" sm="">
            <b-row>
              <b-col sm="6" class="product-title">
                <span class="">{{ prd.product_code_and_name }}</span>
                <b-button :name="`productRemoveButton`" variant="tertiary" class="flex-1 btn-pl-xsm ml-5 btn-remove"
                  @click="removeProduct(prd.plc_prd_key)">{{ translations.tcRemove }}</b-button>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12" class="product-need">
                {{ translations.tcNeeded }}
                <b-form-input :id="`text_needed_${index}`" placeholder
                  v-model="plc.plc_products[index].plc_quantity_needed" class="form-control mr-5 ml-1" type="number">
                </b-form-input>
                {{ translations.tcPlaced }}
                <b-form-input :id="`text_placed_${index}`" placeholder
                  v-model="plc.plc_products[index].plc_quantity_placed" class="form-control ml-1" type="number">
                </b-form-input>
              </b-col>
            </b-row>
          </div>
          <div class="presentation-specs" v-if="!plc.no_scriptures_required_flag && plc.plc_products.length === 0">
            <div class="font-weight-bold">
              <span class="value">{{ translations.tcNoProductsSelected }}</span>
            </div>
          </div>
        </div>
      </section>
      <section v-if="userSelectedLocationKey.length > 0" class="section-4 bg-block mb-4">
        <header class="mb-3">
          <h2 class="font-style-1">{{ translations.tcOtherInformation }}</h2>
        </header>
        <div class="body">
          <textarea name="other" v-model="plc.other_info_note" class="w-100"></textarea>
        </div>
      </section>
      <section class="section-5 pt-3 pl-0 pl-sm-4">
        <div class="body">
          <div class="d-flex">
            <b-button v-if="userSelectedLocationKey.length > 0" variant="primary" @click="handleSaveClick"
              class="flex-0 mr-3 w-100-sd mb-3 mb-sm-0">{{ translations.tcSave }}</b-button>
            <b-button variant="tertiary" @click="handleCancelClick" class="flex-0 w-100-sd mb-sm-0">{{
          translations.tcCancel
        }}</b-button>
          </div>
        </div>
      </section>
    </page-body>
    <PersonSearch @selectionMade="applyFoundPerson($event)" :camp_country="camp_country" :modal_title="modal_title">
    </PersonSearch>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import PersonSearch from '@/components/PersonSearch.vue'
import ToggleArrow from '@/components/toggle-arrow/ToggleArrow.vue'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue2-timepicker/dist/VueTimepicker.css'
import VueTimepicker from 'vue2-timepicker'

export default {
  name: 'add-placement-date',
  mixins: [calendarMixin, numericMixin, translationMixin],
  data() {
    return {
      showDecadeNav: true,
      hideHeader: true,
      translations: {},
      toggle: true,
      section2: true,
      section3: true,
      section4: true,
      timeFormat: 'hh:mm a',
      startTimeData: {
        hh: '12',
        mm: '00',
        A: 'pm',
      },
      endTimeData: {
        hh: '12',
        mm: '00',
        A: 'pm',
      },
      orgKeyAndType: { org_key: null, org_type_key: null },
      plc: {
        placement_key: null,
        location_key: null,
        loc_name: '',
        loc_adr_key: null,
        loc_address_1: '',
        loc_address_2: '',
        loc_city: '',
        loc_state: '',
        loc_postal_code: '',
        loc_country_name: '',
        loc_country_key: '',
        coordinator_ind_key: null,
        coordinator_name: '',
        coordinator_phone: '',
        coordinator_email: '',
        member_participation_type: '',
        member_participation_type_key: null,
        members_required: 0,
        number_of_rooms: 0,
        camp_key: null,
        meeting_key: null,
        mtg_org_type_key: null,
        mtg_start_date: null,
        mtg_end_date: '',
        mtg_start_time: '12:00:00',
        mtg_end_time: '12:30:00',
        status: '',
        other_info_note: '',
        no_scriptures_required_flag: false,
        plc_participants: [],
        plc_products: [],
        delete_participants: [],
        delete_products: []
      },
      selectedMemberType: '9084a3c7-bbf0-4796-b4a9-5b5d95349d4c', //DEFAULT TO GET ALL
      selectedLocationKey: null,
      findButton: '',
      searchedCoordinators: [],
      selectedParticipant: null,
      selectedProduct: null,
      modal_title: 'Individual Search'
    }
  },
  methods: {
    ...mapActions({
      addPlacementDate: 'scriptureDistribution/addPlacementDate',
      clearLocationDetails: 'scriptureDistribution/clearLocationDetails',
      clearPlacementDateDetails: 'scriptureDistribution/clearPlacementDateDetails',
      loadLocationDetails: 'scriptureDistribution/loadLocationDetails',
      loadPlacementDateDetails: 'scriptureDistribution/loadPlacementDateDetails',
      loadPlacementFormOptions: 'scriptureDistribution/loadPlacementFormOptions',
      loadPlacementLocationsByCamp: 'scriptureDistribution/loadPlacementLocationsByCamp',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedLocationKey: 'user/setSelectedLocationKey',
      setSelectedPlacementDateKey: 'user/setSelectedPlacementDateKey',
    }),
    async handleLocationChange(evt) {
      let spl_evt = evt.split('|')
      this.orgKeyAndType.org_key = spl_evt[0]
      this.orgKeyAndType.org_type_key = !!spl_evt[1] ? spl_evt[1] : this.userSelectedOrgTypeKey
      await Promise.all([
        this.setLoadingStatus(true),
        this.setSelectedLocationKey(spl_evt[0]),
        await this.loadLocationDetails(this.orgKeyAndType),
        this.loadPlacementFormOptions(this.locationDetail.member_participation_type_key),
      ]).then(() => {
        this.resetLocalPLC(), this.setLoadingStatus(false)
      })
    },
    async handleCancelClick() {
      this.resetData()
      this.redirectRoute()
    },
    async handleSaveClick() {
      if (
        !this.plc.mtg_start_date ||
        !this.plc.mtg_start_time ||
        !this.plc.mtg_end_time ||
        !this.plc.coordinator_ind_key ||
        (!this.plc.no_scriptures_required_flag && this.plc.plc_products.length === 0)
      ) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorAllFieldsRequired,
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else if (!this.properStartEndTimes(this.plc.mtg_start_time, this.plc.mtg_end_time)) {
        this.$swal({
          icon: 'error',
          text: 'The times must be consecutive. (FYI: 12:00PM is noon).',
          confirmButtonText: this.translations.tcOk || 'Ok',
        }).then((result) => {
          return
        })
      } else {
        let result = true
        this.plc.camp_key = !!this.plc.camp_key ? this.plc.camp_key : this.userCampKey
        this.plc.location_key = this.userSelectedLocationKey
        this.plc.meeting_key = this.userSelectedPlacementDateKey
        this.plc.mtg_org_type_key = this.orgKeyAndType.org_type_key
        this.plc.mtg_start_time = this.formatTimeString(this.plc.mtg_start_time)
        this.plc.mtg_end_time = this.formatTimeString(this.plc.mtg_end_time)
        this.plc.member_participation_type_key = this.locationDetail.member_participation_type_key
        this.plc.status = this.plc.no_scriptures_required_flag ? 'Complete' : ''

        // submit data
        await Promise.all([this.setLoadingStatus(true), (result = this.addPlacementDate(this.plc))]).then(() => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result ? 'success' : 'error',
            text: result ? this.translations.tcPlacementDateSaved : this.translations.tcErrorDuringSave,
            confirmButtonText: this.translations.tcOk || 'Ok',
          }).then((result) => {
            if (result.value) {
              // route to previous page
              this.resetData()
              this.redirectRoute()
            }
          })
        })
      }
    },
    async redirectRoute() {
      let linkBack = this.userRouterBackLink
      this.setRouterBackLink('')
      if (linkBack.length > 0) {
        this.$router.push({ name: linkBack })
      } else {
        this.$router.push('/programs/sd/placement-dates')
      }
    },
    async applyFoundPerson(evt) {
      switch (this.findButton) {
        case 'coordinatorFindButton':
          this.searchedCoordinators.push({
            value: evt.ind_key,
            text: evt.name_text,
          })
          this.plc.coordinator_ind_key = evt.ind_key
          break
        case 'participantFindButton':
          //check if they already exist, if so null out selection and return
          if (this.plc.plc_participants.length !== 0) {
            let pi = this.plc.plc_participants.findIndex((p) => p.pxi_ind_key === evt.ind_key)
            if (pi >= 0) {
              this.selectedParticipant = null
              break
            }
          }
          this.plc.plc_participants.push({
            name: evt.name_text,
            pxi_ind_key: evt.ind_key,
            pxi_mro_key: 'c67a8fe3-f1f9-46f9-84a0-dca132a65acc',
            role: 'Placement Participant',
          })
          this.selectedParticipant = null
          break
        default:
          this.searchedCoordinators.push({
            value: evt.ind_key,
            text: evt.name_text,
          })
          this.plc.coordinator_ind_key = evt.ind_key
          break
      }
    },
    resetLocalPLC() {
      this.plc = {
        placement_key: null,
        location_key: null,
        loc_name: '',
        loc_adr_key: null,
        loc_address_1: '',
        loc_address_2: '',
        loc_city: '',
        loc_state: '',
        loc_postal_code: '',
        loc_country_name: '',
        loc_country_key: '',
        coordinator_ind_key: null,
        coordinator_name: '',
        coordinator_phone: '',
        coordinator_email: '',
        member_participation_type: '',
        member_participation_type_key: null,
        members_required: 0,
        number_of_rooms: 0,
        camp_key: null,
        meeting_key: null,
        mtg_org_type_key: null,
        mtg_start_date: null,
        mtg_end_date: '',
        mtg_start_time: '12:00:00',
        mtg_end_time: '12:30:00',
        status: '',
        other_info_note: '',
        no_scriptures_required_flag: false,
        plc_participants: [],
        plc_products: [],
        delete_participants: [],
        delete_products: []
      }
    },
    selectAParticipant(evt) {
      //check if they already exist, if so null out selection and return
      if (this.plc.plc_participants.length !== 0) {
        let pi = this.plc.plc_participants.findIndex((p) => p.pxi_ind_key === evt)
        if (pi >= 0) {
          this.selectedParticipant = null
          return
        }
      }
      let newP = this.convertedPlacementFormOptions.members.find((m) => m.value === evt)
      this.plc.plc_participants.push({
        name: newP.text,
        pxi_ind_key: newP.value,
        pxi_mro_key: 'c67a8fe3-f1f9-46f9-84a0-dca132a65acc',
        role: 'Placement Participant',
      })
      this.selectedParticipant = null
      return
    },
    selectAProduct(evt) {
      //check if they already exist, if so null out selection and return
      if (this.plc.plc_products.length !== 0) {
        let pi = this.plc.plc_products.findIndex((p) => p.plc_prd_key === evt)
        if (pi >= 0) {
          this.selectedProduct = null
          return
        }
      }
      let newP = this.convertedPlacementFormOptions.products.find((m) => m.value === evt)
      this.plc.plc_products.push({
        plc_prd_key: newP.value,
        plc_quantity_needed: 0,
        plc_quantity_placed: 0,
        product_code_and_name: newP.text,
      })
      this.selectedProduct = null
      return
    },
    removeParticipant(evt) {
      const searchItem = (t) => t.pxi_ind_key === evt
      const idx = this.plc.plc_participants.findIndex(searchItem)
      let dp = this.plc.plc_participants.find(searchItem)
      this.plc.delete_participants.push(dp)
      this.plc.plc_participants.splice(idx, 1)
    },
    removeProduct(evt) {
      const searchItem = (t) => t.plc_prd_key === evt
      const idx = this.plc.plc_products.findIndex(searchItem)
      let dp = this.plc.plc_products.find(searchItem)
      this.plc.delete_products.push(dp)
      this.plc.plc_products.splice(idx, 1)
    },
    async showLocationDropdown() {
      this.setLoadingStatus(true)
      this.resetData()
      await Promise.all([await this.setSelectedLocationKey('')]).then(() => {
        this.clearPlacementDateDetails(),
          (this.startTimeData = {
            hh: '12',
            mm: '00',
            A: 'pm',
          }),
          (this.endTimeData = {
            hh: '12',
            mm: '00',
            A: 'pm',
          })
      })
      this.setLoadingStatus(false)
    },
    async setTime() {
      if (this.plc.mtg_start_time !== null) {
        this.plc.mtg_start_time = this.formatTimeToMilitary(this.plc.mtg_start_time)
      }
      if (this.plc.mtg_end_time !== null) {
        this.plc.mtg_end_time = this.formatTimeToMilitary(this.plc.mtg_end_time)
      }
    },
    showModal(e, modalName) {
      this.findButton = !e.target ? e : e.target.name
      switch (this.findButton) {
        case 'coordinatorFindButton':
          this.modal_title = this.translations.tcCoordinatorSearch
          break
        case 'participantFindButton':
          this.modal_title = this.translations.tcParticipantSearch
          break
        default:
          this.modal_title = this.translations.tcIndividualSearch
          break
      }
      this.$root.$emit('bv::show::modal', `${modalName}`)
    },
    async resetData() {
      await this.clearLocationDetails()
      await this.clearPlacementDateDetails()
      await this.setSelectedPlacementDateKey('')
        ; (this.selectedParticipant = null), (this.selectedProduct = null)
    },
  },
  async created() {
    this.setLoadingStatus(true)

    await this.getViewTranslations()
    if (this.userSelectedLocationKey === '') {
      await Promise.all([this.loadPlacementLocationsByCamp(this.selectedMemberType)]).then(() => {
        this.resetLocalPLC()
      })
    } else {
      this.selectedLocationKey = this.userSelectedLocationKey
      await this.handleLocationChange(this.userSelectedLocationKey)
      await this.loadPlacementLocationsByCamp(this.selectedMemberType)
    }

    // decide here: add or edit
    if (this.userSelectedPlacementDateKey !== null && this.isGuid(this.userSelectedPlacementDateKey)) {
      this.setLoadingStatus(true)
      this.orgKeyAndType.org_key = this.userSelectedLocationKey
      this.orgKeyAndType.org_type_key = this.locationDetail.org_type_key
      await Promise.all([
        this.loadLocationDetails(this.orgKeyAndType),
        this.loadPlacementDateDetails(this.userSelectedPlacementDateKey),
      ]).then(() => {
        this.plc = { ...this.placementDateDetails }
        this.plc.mtg_start_date = new Date(this.plc.mtg_start_date)
        this.setTime()
      })
      this.setLoadingStatus(false)
    }

    await Promise.all([
      this.loadPlacementFormOptions(
        !!this.placementDateDetails.member_participation_type_key
          ? this.placementDateDetails.member_participation_type_key
          : this.locationDetail.member_participation_type_key
      ),
    ]).then(() => {
      let coord = this.coordinatorOptions.findIndex((c) => c.value === this.plc.coordinator_ind_key)
      if (coord < 0) {
        this.searchedCoordinators.push({
          value: this.plc.coordinator_ind_key,
          text: this.plc.coordinator_name,
        })
      }
    })
    this.setLoadingStatus(false)
  },
  computed: {
    ...mapGetters({
      convertedPlacementFormOptions: 'scriptureDistribution/convertedPlacementFormOptions',
      locationDetail: 'scriptureDistribution/locationDetail',
      locationsForSelection: 'scriptureDistribution/locationsForSelection',
      placementDateDetails: 'scriptureDistribution/placementDateDetails',
      placementFormOptions: 'scriptureDistribution/placementFormOptions',
      placement_locations: 'scriptureDistribution/placement_locations',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userRouterBackLink: 'user/userRouterBackLink',
      userSelectedLocationKey: 'user/userSelectedLocationKey',
      userSelectedOrgTypeKey: 'user/userSelectedOrgTypeKey',
      userSelectedPlacementDateKey: 'user/userSelectedPlacementDateKey',
    }),
    coordinatorOptions() {
      return [...this.convertedPlacementFormOptions.members]
    },
    camp_country() {
      return this.convertedPlacementFormOptions.campCountry.value
    },
  },
  components: {
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
    PersonSearch: PersonSearch,
    ToggleArrow: ToggleArrow,
    VueTimepicker: VueTimepicker,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.participant {
  width: 280px;
  display: inline-block;
}

.products {
  width: 280px;
  display: inline-block;
}

.product-container {
  .product-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    .btn-remove {
      max-width: 100px;
      width: 100px;
    }
  }

  .product-need {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;

    input {
      width: 90px !important;
      text-align: center;
    }
  }
}

.clockIcon {
  border-right: 0px;
  width: 51px;
  height: 51px;
  display: inline;
  padding: 10px;
  position: relative;
}

.list-border {
  border-bottom: 1px solid #979797;
  margin-bottom: 24px;
  padding-bottom: 22px;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
  }
}

h3 {
  line-height: 1;
}

hr {
  max-width: 671px;
  margin: 25px 0;
  background-color: #979797;
}

input[type='number'] {
  height: auto;
  max-width: 85px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

.vdp-datepicker div input {
  border: 1px solid #636363;
  padding: 10px 20px !important;
  width: 18em;
  font-weight: 600;
  text-transform: uppercase;
}

.vdp-datepicker__calendar {
  font-weight: 600;
  text-transform: uppercase;
}

.bg-block {
  padding: 34px 32px;

  @include breakpoint(sm) {
    padding: 25px 20px;
  }
}

.change-link {
  display: inline-flex;
  color: #118a0d;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 20px;
}

.section-2,
.section-3,
.section-4 {
  padding-top: 22px;
}

.custom-checkbox {
  display: flex;
  height: 1.5rem;
  width: 55px;
  padding: 0;

  @include breakpoint(sm) {
    width: 24px;
  }

  input {
    order: 2;
  }

  label {
    position: relative;
    order: 1;
    height: 1.5rem;
    width: 1.5rem;

    &:before,
    &:after {
      height: 1.5rem;
      width: 1.5rem;
      left: 0;
      top: 0;
    }
  }
}

input[type='checkbox'] {
  height: auto;
  max-width: 55px;
  padding: 10px 0;
  line-height: 25px;
  text-align: center;
}

textarea {
  height: 125px;
  padding: 10px 20px !important;
  border-color: #636363 !important;
  color: #003946 !important;
  font-weight: 700 !important;
  line-height: 25px !important;
}

.page-body {
  padding: 80px 15px;
}

.g-row {
  display: flex;
  align-items: center;

  @include breakpoint(sm) {
    flex-wrap: wrap;
  }

  .g-col-1 {
    max-width: 424px;
    width: 100%;
    padding-right: 43px;

    @include breakpoint(sm) {
      max-width: 100%;
      padding-right: 0;
    }
  }

  .g-col-2 {
    max-width: 247px;
    width: 100%;

    @include breakpoint(sm) {
      max-width: 100%;
    }
  }
}

form {
  select.form-control.flex-1 {
    flex: 1 1 auto;
  }
}

.date-select-1 {
  width: 45%;

  @include breakpoint(sm) {
    width: 100%;
  }

  text-transform: uppercase;

  .start-input {
    width: 287px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }
}

.time-select-1 {
  text-transform: uppercase;

  .start-time {
    width: 291px;

    @include breakpoint(sm) {
      &:before {
        content: '';
        z-index: 1;
      }
    }
  }

  .time-wrap {
    border: 1px solid #636363;
  }
}

.col-left-input {
  width: 291px !important;
}

.section-5 {
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
    }
  }
}

.cal-icon {
  width: 25px;

  @include breakpoint(sm) {
    width: 40px;
  }
}

// MOVE GLOBAL
.toggle-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
